<template>
  <div class="question">
    {{ question.libelle }}  --> <span class="strong anwser">[{{ response }}] </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {question: {type: Object, required: true}},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            questions: 'sanitaire/questions',
        responses: 'sanitaire/responses',
        animaux: 'identification/animaux'
    }),
    selectedQuestion(){
        return this.questions.find(item => item.uid === this.question.uid)
    },
    response(){
        if(this.selectedQuestion !== undefined && this.selectedQuestion.libre) return this.question.answer
        if(this.question.uid !== 0 ) return this.responses.find(item => item.uid === this.question.answer).libelle
        
        return this.animaux.find(item => item.uid === this.question.answer).codeIso
    }
    
}
}
</script>

<style lang="scss" scoped>
.question{
    padding: 5px 20px;
    &::before{
        height: 25px;
        width: 25px;
        background-color: #070101;
        border-radius: 50%;
        display: inline-block;
    }
    span{
        margin-left: 1rem;
    }
    .anwser{
        color: #B50404;
    }
}
</style>