<template>
  <div class="profile-info">
    <img
      :src="photo"
      width="100%"
    >
    <hr>
    <div class="row">
      <div class="col-6">
        <h5>{{ name }}</h5>
        <div class="single-profile-info">
          <h6>Code Iso</h6>
          <p>{{ codeIso }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Sexe</h6>
          <p>{{ sexe|sexe }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Espèce</h6>
          <p>{{ espece }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Race</h6>
          <p>{{ race }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Robe</h6>
          <p>{{ robe }}</p>
        </div>
      </div>
      <div class="col-6">
        <user-inline
          :uid="proprioUid"
          class="proprio"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import UserInline from '../../admin/user/userInline.vue' 
const animalPlaceholder = require('../../../assets/img/animalPlaceholder.png')
export default {
  components: { UserInline },
    props: {animalId:  {type: String, required: true}},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            especes: 'identification/especes',
            robes: 'identification/robes',
            races: 'identification/races',
            users: 'auth/users'
        }),
        animal(){
            let an = this.animaux.find(item => item.uid === this.animalId)
            if(an) return an
            return null
        },  
        hasAnimal () {return this.animal !== null && this.animal !== undefined},
        photo(){
            if(this.hasAnimal) return this.animal.photo !== null ? JSON.parse(this.animal.photo).url : animalPlaceholder
            return animalPlaceholder 
        },
        name(){
            return this.hasAnimal ?   this.animal.name : '-'
        },
        codeIso(){
           return this.hasAnimal ?  this.animal.codeIso : '-'
        },
        sexe(){
           return this.hasAnimal ?  this.animal.sexe : '-'
        },
        espece(){
            if(this.hasAnimal) {
                let e = this.especes.find(item => item.uid === this.animal.espece)
                if(e) return e.libelle
            }
            return '-'
        },
        race(){
            if(this.hasAnimal) {
                let r = this.races.find(item => item.uid === this.animal.race)
                if(r) return r.libelle
            }
            return '-'
        },
        robe(){
            if(this.hasAnimal){
                let r = this.robes.find(item => item.uid === this.animal.robe)
                if(r) return r.libelle
            }
            return '-'
        },
        proprioUid(){
          if(this.hasAnimal) return this.animal.proprio
          return null
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-info{
  border-radius: 0px !important;
}
.proprio{
  position: absolute;
  right: 20px;
  bottom: 20px;
}

</style>