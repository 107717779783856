<template>
  <div>
    <div
      class="row"
      v-for="s in reviewExamen"
      :key="s.uid"
    >
      <div class="col-12 e-c-section">
        <h5> {{ s.libelle }}</h5>
        <div
          class="row"
          v-for="q in s.questions"
          :key="q.uid"
        >
          <div class="col-12">
            <answer :question="q" />
            <!-- <p>{{q.libelle}} <span>[ {{q.answer}}] </span></p>  -->
          </div>
        </div>
      </div>
    </div>
    <!-- {{reviewExamen}} -->
  </div>
</template>


<script>
import {mapGetters} from 'vuex'
import Answer from './answer.vue'
export default {
    components: {Answer},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            reviewExamen: 'sanitaire/reviewExamen'
        }),
        orderReview() {
            return []
        }
    }
}

</script>


<style lang="scss" scoped>
.e-c-section{
    padding: 20px 10px;
    h5{
      color: #1C6100;
    }
}

</style>